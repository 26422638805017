import {
  RouteReuseStrategy,
  DetachedRouteHandle,
  ActivatedRouteSnapshot,
} from '@angular/router';

export class CacheRouteReuseStrategy implements RouteReuseStrategy {
  storedRouteHandles = new Map<string, DetachedRouteHandle>();
  allowRetriveCache = {
    home: true,
  };

  // If returns true then the store method will be invoked
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    const name = this.getConfiguredUrl(route);
    if (this.allowRetriveCache.hasOwnProperty(name)) {
      return true;
    }
    return false;
  }

  // This method is invoked only if the shouldDetach returns true
  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    this.storedRouteHandles.set(this.getConfiguredUrl(route), handle);
  }

  // If this method returns true then retrieve method will be called
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    const name = this.getConfiguredUrl(route);

    if (this.allowRetriveCache[name]) {
      return this.storedRouteHandles.has(this.getConfiguredUrl(route));
    }

    if (this.getConfiguredUrl(route) === 'profile') {
      this.allowRetriveCache.home = false;
    } else {
      this.allowRetriveCache.home = true;
    }

    return false;
  }

  // This method is called if shouldAttach returns true
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    return this.storedRouteHandles.get(this.getConfiguredUrl(route));
  }

  shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot
  ): boolean {
    return future.routeConfig === curr.routeConfig;
  }

  private getConfiguredUrl(route: ActivatedRouteSnapshot): string {
    return route.pathFromRoot
      .filter((v) => v.routeConfig && v.routeConfig.path !== '')
      .map((v) => v.routeConfig.path)
      .join('/');
  }
}
