import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { OrderService } from 'src/app/services/order.service';
import { NzCarouselComponent } from 'ng-zorro-antd/carousel';
import { NzModalService } from 'ng-zorro-antd/modal';
import { SalesPointInfoRenewComponent } from '../sales-point-info-renew/sales-point-info-renew.component';

@Component({
  selector: 'app-property-info',
  templateUrl: './property-info.component.html',
  styleUrls: ['./property-info.component.css'],
})
export class PropertyInfoComponent implements OnInit {
  private prefix = 'https://s3.ap-south-1.amazonaws.com/res-bbmedia-in/';
  imageArr = [];
  nzSelectedIndex = 1;
  @ViewChild(NzCarouselComponent) carousel: NzCarouselComponent;
  viewer: any;

  currentImage = 0;

  @Input() property: any;
  @Input() source: any;
  @Input() showDetail: boolean;
  @Output() propertyInfoCloseEvent = new EventEmitter<string>();

  propertyDetail: any = {};
  isDetailLoading = false;
  isShowPropertyDetail = false;

  genderChartOption: any;
  educationalChartOption: any;
  ageChartOption: any;
  marriageChartOption: any;
  incomeChartOption: any;
  consumptionChartOption: any;
  carChartOption: any;

  constructor(
    private orderService: OrderService,
    private modalService: NzModalService
  ) {}

  ngOnInit() {
    this.isShowPropertyDetail = true;
    this.showPropertyDetail();

    if (this.property.orderImage) {
      this.buildImage();
      this.onSelectedIndexChange(0);
    }
  }

  showPropertyDetail() {
    this.isDetailLoading = true;
    this.propertyDetail = {};

    this.orderService
      .findPropertyInfo(this.property.propertyId)
      .subscribe((response: any) => {
        this.propertyDetail = response;

        this.propertyDetail.towerNumber = this.propertyDetail.towerNumber
          ? this.propertyDetail.towerNumber
          : 0;
        this.propertyDetail.liftNumber = this.propertyDetail.liftNumber
          ? this.propertyDetail.liftNumber
          : 0;
        this.propertyDetail.screenNumber = this.propertyDetail.screenNumber
          ? this.propertyDetail.screenNumber
          : 0;
        this.propertyDetail.standingScreen = this.propertyDetail.standingScreen
          ? this.propertyDetail.standingScreen
          : 0;
        this.propertyDetail.posterNumber = this.propertyDetail.posterNumber
          ? this.propertyDetail.posterNumber
          : 0;
        this.propertyDetail.magicPoster = this.propertyDetail.magicPoster
          ? this.propertyDetail.magicPoster
          : 0;
        const images = [];
        if (this.propertyDetail.images && this.propertyDetail.images !== '') {
          this.propertyDetail.images.split(',').forEach((imageTemp) => {
            if (!imageTemp.startsWith('http')) {
              images.push(
                'https://s3.ap-south-1.amazonaws.com/res-bbmedia-in/' +
                  imageTemp
              );
            } else {
              images.push(imageTemp);
            }
          });
        } else {
          images.push('assets/images/screen.jpg');
          images.push('assets/images/poster.jpg');
        }
        this.propertyDetail.imagesTemp = images;

        this.genderChart();
        this.educationalChart();
        this.ageChart();
        this.marriageChart();
        this.incomeChart();
        this.consumptionChart();
        this.carChart();
        this.isDetailLoading = false;
      });
  }

  genderChart() {
    this.genderChartOption = {
      tooltip: {
        trigger: 'item',
        formatter: '{b}',
      },
      series: [
        {
          type: 'pie',
          radius: '80%',
          selectedMode: 'single',
          color: ['#fcc3c6', '#30c3d4'],
          data: [
            {
              value: this.propertyDetail.maleRatio,
              name: 'Male - ' + this.propertyDetail.maleRatio + '%',
            },
            {
              value: 40,
              name: 'Female - ' + this.propertyDetail.femaleRatio + '%',
            },
          ],
        },
      ],
    };
  }

  educationalChart() {
    this.educationalChartOption = {
      // tooltip: {
      //   trigger: 'item',
      //   formatter: '{b}',
      // },
      tooltip: {
        trigger: 'item',
        formatter: '{b} : {d}%',
      },
      series: [
        {
          type: 'pie',
          radius: '80%',
          selectedMode: 'single',
          color: ['#30c3d4', '#fa4b47', '#f9c878'],
          data: [
            {
              value: this.propertyDetail.educationLow,
              name: 'Low – Below 10th Standard',
            },
            {
              value: this.propertyDetail.educationMedium,
              name: 'Medium – 11th – Graduate',
            },
            {
              value: this.propertyDetail.educationHigh,
              name: 'High – Master’s above',
            },
          ],
        },
      ],
    };
  }

  ageChart() {
    this.ageChartOption = {
      legend: {},
      tooltip: {},
      dataset: {
        source: [
          [
            'product',
            'Children(0-14 Yr)',
            'Young(15-30 Yr)',
            'Middle-age(31-60 Yr)',
            'Elderly(61+ Yr)',
            '',
          ],
          [
            '',
            this.propertyDetail.agrChildren,
            this.propertyDetail.agrYouth,
            this.propertyDetail.agrMiddle,
            this.propertyDetail.agrElderly,
            0,
          ],
        ],
      },
      xAxis: { type: 'category' },
      yAxis: {},
      series: [
        { type: 'bar', color: '#fcc3c6' },
        { type: 'bar', color: '#fdc160' },
        { type: 'bar', color: '#30c3d4' },
        { type: 'bar', color: '#fa4b47' },
        { type: 'bar' },
      ],
    };
  }

  marriageChart() {
    this.marriageChartOption = {
      tooltip: {
        trigger: 'item',
        formatter: '{b}',
      },
      series: [
        {
          type: 'pie',
          radius: '80%',
          selectedMode: 'single',
          color: ['#fcc3c6', '#30c3d4'],
          data: [
            {
              value: this.propertyDetail.married,
              name: 'Married-' + this.propertyDetail.married + '%',
            },
            {
              value: 100 - this.propertyDetail.married,
              name: 'Single-' + (100 - this.propertyDetail.married) + '%',
            },
          ],
        },
      ],
    };
  }

  incomeChart() {
    this.incomeChartOption = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      legend: {
        data: [
          'Low – Less than or equal to 10lakh',
          'Medium – 11lakh to 20lakh',
          'High – 20 lakh',
        ],
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },
      xAxis: {
        type: 'value',
        boundaryGap: [0, 0.01],
      },
      yAxis: {
        type: 'category',
        data: [''],
      },
      series: [
        {
          name: 'Low – Less than or equal to 10lakh',
          type: 'bar',
          data: [this.propertyDetail.incomeLow],
          color: '#b3fff0',
        },
        {
          name: 'Medium – 11lakh to 20lakh',
          type: 'bar',
          data: [this.propertyDetail.incomeMedium],
          color: '#99b3ff',
        },
        {
          name: 'High – 20 lakh',
          type: 'bar',
          data: [this.propertyDetail.incomeHigh],
          color: '#cc99ff',
        },
      ],
    };
  }

  consumptionChart() {
    this.consumptionChartOption = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      legend: {
        data: [
          'Low – Less than or equal to 30k',
          'Medium – 30k-50k',
          'High – Above 50k',
        ],
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },
      xAxis: {
        type: 'value',
        boundaryGap: [0, 0.01],
      },
      yAxis: {
        type: 'category',
        data: [''],
      },
      series: [
        {
          name: 'Low – Less than or equal to 30k',
          type: 'bar',
          data: [this.propertyDetail.consumptionLow],
          color: '#ffd9b3',
        },
        {
          name: 'Medium – 30k-50k',
          type: 'bar',
          data: [this.propertyDetail.consumptionMedium],
          color: '#ffeb99',
        },
        {
          name: 'High – Above 50k',
          type: 'bar',
          data: [this.propertyDetail.consumptionHigh],
          color: '#ff99bb',
        },
      ],
    };
  }

  carChart() {
    this.carChartOption = {
      tooltip: {
        trigger: 'item',
        formatter: '{b}',
      },
      series: [
        {
          type: 'pie',
          radius: '80%',
          selectedMode: 'single',
          color: ['#fa4b47', '#fdc160'],
          data: [
            {
              value: this.propertyDetail.vehicleQuantity,
              name: 'Private - ' + this.propertyDetail.vehicleQuantity + '%',
            },
            {
              value: 100 - this.propertyDetail.vehicleQuantity,
              name:
                'No vehicle - ' +
                (100 - this.propertyDetail.vehicleQuantity) +
                '%',
            },
          ],
        },
      ],
    };
  }

  close() {
    this.isShowPropertyDetail = false;
    this.propertyInfoCloseEvent.emit('close');
  }

  showPoints() {
    if (this.source) {
      const modal = this.modalService.create({
        nzTitle: '',
        nzContent: SalesPointInfoRenewComponent,
        nzComponentParams: {
          property: this.property,
        } as any,
        nzFooter: null,
        nzClosable: false,
        nzBodyStyle: {
          padding: '0',
        },
        nzWidth: '70vw',
        nzStyle: { 'min-width': '700px' },
      });

      modal.afterClose.subscribe((result) => {
        console.log('dialog closed.');
      });
    } else {
      const modal = this.modalService.create({
        nzTitle: '',
        // nzContent: SalesPointInfoComponent,
        nzComponentParams: {
          property: this.property,
        } as any,
        nzFooter: null,
        nzClosable: false,
        nzBodyStyle: {
          padding: '0',
        },
        nzWidth: '70vw',
        nzStyle: { 'min-width': '700px' },
      });

      modal.afterClose.subscribe((result) => {
        console.log('dialog closed.');
      });
    }
  }

  buildImage() {
    if (this.property.orderImage) {
      const array = this.property.orderImage.map((image) => {
        if (image.images.indexOf(this.prefix) === -1) {
          return this.prefix + image.images;
        }
        return image.images;
      });
      this.imageArr[0] = array;
    } else {
      this.imageArr[0] = [];
      this.imageArr[0].push('assets/images/screen.jpg');
      this.imageArr[0].push('assets/images/poster.jpg');
    }

    if (this.property.images) {
      let arr = this.property.images.split(',');
      arr = arr.map((value) => {
        if (value.indexOf(this.prefix) === -1) {
          value = this.prefix + value;
        }
        return value;
      });
      this.imageArr[1] = arr;
    } else {
      this.imageArr[1] = [];
      this.imageArr[1].push('assets/images/screen.jpg');
      this.imageArr[1].push('assets/images/poster.jpg');
    }
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngAfterViewInit() {
    if (this.property.orderImage) {
      this.buildViewer();
      document.querySelector('.alt').addEventListener('click', (event) => {
        this.viewer.view(
          this.currentImage % this.imageArr[this.nzSelectedIndex].length
        );
      });
    }
  }

  carouselChange(index: number) {
    this.currentImage = index;
  }

  onSelectedIndexChange(index: number) {
    setTimeout(() => {
      this.buildViewer();
    });
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngOnDestroy() {
    if (this.viewer) {
      this.viewer.destroy();
    }
  }

  buildViewer() {
    this.ngOnDestroy();

    const overlayClassName = 'cdk-overlay-pane';
    const elementsArr = document.getElementsByClassName(
      'cdk-overlay-container'
    );
    let container;
    for (const element of Array.from(elementsArr)) {
      if (element.querySelector('app-building-info-modal')) {
        container = element;
        break;
      }
    }

    this.viewer = new Viewer(this.carousel.el, {
      container: container || '',
      className: overlayClassName,
    });

    const viewerRootContainer = document.getElementsByClassName(
      'viewer-container'
    );
    this.carousel.el.addEventListener('show', () => {
      for (const ele of Array.from(viewerRootContainer)) {
        if (!ele.classList.contains(overlayClassName)) {
          ele.classList.add(overlayClassName);
        }
      }
    });

    this.carousel.el.addEventListener('hidden', () => {
      for (const ele of Array.from(viewerRootContainer)) {
        if (ele.classList.contains(overlayClassName)) {
          ele.classList.remove(overlayClassName);
        }
      }
    });
  }

  handleOk(): void {
    console.log('Button ok clicked!');
  }

  handleCancel(): void {
    console.log('Button cancel clicked!');
  }
}
