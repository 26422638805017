<nz-card nzTitle="Select export columns" [nzExtra]="extraTemplate">
  <div *ngIf="isAnySelect" style="color: red; margin-bottom: 10px">
    Please select the column to export
  </div>

  <div nz-row [nzGutter]="16">
    <div
      *ngFor="let header of headers"
      nz-col
      [nzSm]="24"
      [nzXs]="24"
      [nzMd]="8"
      [nzLg]="6"
      [nzXl]="6"
      style="margin-bottom: 15px"
    >
      <label
        nz-checkbox
        [(ngModel)]="header.checked"
        (ngModelChange)="selectUnselect()"
        >{{ header.header }}</label
      >
    </div>
  </div>

  <div style="text-align: right">
    <button
      nz-button
      nzType="default"
      (click)="close()"
      style="margin-right: 20px"
    >
      Cancel
    </button>
    <button nz-button nzType="primary" (click)="export()">Export</button>
  </div>
</nz-card>

<ng-template #extraTemplate>
  <nz-select
    [(ngModel)]="groupBy"
    *ngIf="fileName === 'Sales'"
    style="margin-right: 10px; width: 120px"
  >
    <nz-option nzValue="city" nzLabel="By City"></nz-option>
    <nz-option nzValue="state" nzLabel="By State"></nz-option>
  </nz-select>

  <label
    nz-checkbox
    [(ngModel)]="allSelect"
    [nzIndeterminate]="indeterminate"
    (ngModelChange)="toggle()"
    >Select all</label
  >
</ng-template>
