import { SalesService } from 'src/app/services/sales.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  title = 'dsp-client';

  constructor(private salesService: SalesService) {}

  ngOnInit() {
    this.recoverFromMemory();
  }

  recoverFromMemory() {
    this.salesService.recoverFromLocalStorage();
  }
}
