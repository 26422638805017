import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainPipeComponent } from './main-pipe.component';
import { PointLocationPipe } from '../pipes/point-location.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [MainPipeComponent, PointLocationPipe],
  exports: [PointLocationPipe],
})
export class MainPipeModule {}
