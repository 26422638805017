import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectionExportComponent } from './selection-export.component';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzSelectModule } from 'ng-zorro-antd/select';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NzFormModule,
    ReactiveFormsModule,
    NzCardModule,
    NzCheckboxModule,
    NzGridModule,
    NzDividerModule,
    NzButtonModule,
    NzSelectModule,
  ],
  declarations: [SelectionExportComponent],
  exports: [SelectionExportComponent],
  entryComponents: [SelectionExportComponent],
})
export class SelectionExportModule {}
