import { Component, Input, OnInit } from '@angular/core';
import { PointResourceInfo } from 'src/app/layouts/classes/response/order/PointResourceInfo';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-sales-point-info-renew',
  templateUrl: './sales-point-info-renew.component.html',
})
export class SalesPointInfoRenewComponent implements OnInit {
  @Input() property?: any;
  tabs = [];
  selectedIndex = 0;
  pageNo = 1;
  isPointDisabled = false;
  loading = false;
  pointData: PointResourceInfo[] = [];
  pointDataAll: any[];
  isCheckedAll: boolean;
  isIndeterminate: boolean;

  constructor(private orderService: OrderService) {}

  ngOnInit() {
    this.findAllPoints();
    this.tabs = ['Screens', 'Standing Screens', 'Frame Posters'];
  }

  tabChange(index: number) {
    this.selectedIndex = index;
    this.pageNo = 1;
    this.pointData = this.pointDataAll[index];
    this.checkSelection();
  }

  findAllPoints() {
    this.loading = true;
    this.orderService
      .findAllPoints(this.property.propertyId)
      .subscribe((response: any) => {
        this.selectedIndex = 0;
        this.loading = false;

        this.pointDataAll = [];
        this.pointDataAll.push(response.screens);
        this.pointDataAll.push(response.standingScreens);
        this.pointDataAll.push(response.posters);

        this.initializeData();
        this.checkSelection();
      });
  }

  initializeData() {
    this.pointDataAll.forEach((points) => {
      points.forEach((point) => {
        if (
          this.property.availableScreen.indexOf(point.id) === -1 &&
          this.property.availableStandingScreen.indexOf(point.id) === -1 &&
          this.property.availableFramePoster.indexOf(point.id) === -1
        ) {
          point.isDisabled = true;
        }
        if (
          this.property.selectedScreen.indexOf(point.id) !== -1 ||
          this.property.selectedStandingScreen.indexOf(point.id) !== -1 ||
          this.property.selectedFramePoster.indexOf(point.id) !== -1
        ) {
          point.isChecked = true;
        }
      });
    });
    this.pointData = this.pointDataAll[this.selectedIndex];
    this.checkSelection();
  }

  masterToggle(event: any) {
    this.pointData
      .filter((point) => !point.isDisabled)
      .forEach((point) => (point.isChecked = event));
    this.checkSelection();
    this.setPropertyPointSelection();
  }

  onPointChecked(point: PointResourceInfo, event: any) {
    point.isChecked = event;
    this.checkSelection();
    this.setPropertyPointSelection();
  }

  checkSelection() {
    const rowDisabled = this.pointData.filter((point) => point.isDisabled)
      .length;
    if (rowDisabled === this.pointData.length) {
      this.isPointDisabled = true;
      this.isCheckedAll = false;
      this.isIndeterminate = false;
    } else {
      this.isPointDisabled = false;
      const rowSelected = this.pointData.filter(
        (point) => !point.isDisabled && point.isChecked
      ).length;
      if (rowSelected === this.pointData.length) {
        this.isCheckedAll = true;
        this.isIndeterminate = false;
      } else if (rowSelected > 0) {
        this.isCheckedAll = false;
        this.isIndeterminate = true;
      } else {
        this.isCheckedAll = false;
        this.isIndeterminate = false;
      }
    }
  }

  setPropertyPointSelection() {
    const selectedPointIds = this.pointData
      .filter((point) => !point.isDisabled && point.isChecked)
      .map((point) => point.id);
    if (this.selectedIndex === 0) {
      this.property.selectedScreen = selectedPointIds;
    } else if (this.selectedIndex === 1) {
      this.property.selectedStandingScreen = selectedPointIds;
    } else if (this.selectedIndex === 2) {
      this.property.selectedFramePoster = selectedPointIds;
    }

    const totalAvailable =
      this.property.availableScreen.length +
      this.property.availableStandingScreen.length +
      this.property.availableFramePoster.length;
    const totalSelected =
      this.property.selectedScreen.length +
      this.property.selectedStandingScreen.length +
      this.property.selectedFramePoster.length;

    if (totalAvailable === totalSelected) {
      this.property.isChecked = true;
      this.property.isIndeterminate = false;
    } else if (totalSelected > 0) {
      this.property.isChecked = false;
      this.property.isIndeterminate = true;
    } else {
      this.property.isChecked = false;
      this.property.isIndeterminate = false;
    }
  }
}
