import { TokenService } from './services/token.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData, CommonModule, DatePipe } from '@angular/common';
import en from '@angular/common/locales/en';
import { AgmCoreModule } from '@agm/core';
import { RouteReuseStrategy } from '@angular/router';
import { CacheRouteReuseStrategy } from './strategy/CacheRouteReuseStrategy';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { StorageModule } from '@ngx-pwa/local-storage';
import { SelectionExportModule } from './layouts/authorized/share/selection-export/selection-export.module';
import { ShowPointInfoModule } from './layouts/authorized/share/show-point-info/show-point-info.module';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { environment } from 'src/environments/environment';

registerLocaleData(en);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CommonModule,
    NzMessageModule,
    NzModalModule,
    SelectionExportModule,
    ShowPointInfoModule,
    AgmCoreModule.forRoot({
      apiKey: environment.GOOGLE_API_KEY,
    }),
    StorageModule.forRoot({ IDBNoWrap: true }),
  ],
  providers: [
    { provide: DatePipe },
    { provide: NZ_I18N, useValue: en_US },
    { provide: HTTP_INTERCEPTORS, useClass: TokenService, multi: true },
    { provide: RouteReuseStrategy, useClass: CacheRouteReuseStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
